@import "../global.scss";

.contact-bar {
  display: flex;
  position: fixed;
  z-index: 49;
  //center
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @include mobile {
    flex-direction: column;
  }

  .about-me {
    display: flex;
    width: 550px;
    height: 550px;
    background-color: var(--project-background);
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    overflow: hidden;
    padding: 20px 30px;
    color: var(--highText-color);
    flex-direction: column;
    z-index: 51;

    @include mobile {
      width: 95%;
      height: 100%;
      padding: 0px 20px;
      border-radius: 0%;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      overflow-y: scroll;
    }

    .about-me-title {
      @include mobile {
        text-align: center;
        font-size: 1.5rem;
      }
    }

    .about-me-description-container {
      display: flex;
      height: 100%;

      .vertical-line {
        width: 5px;
        height: 100%;
        background-color: var(--highText-color);
        margin-right: 10px;

        @include mobile {
          display: none;
        }
      }

      .about-me-description-text {
        display: flex;
        flex-direction: column;
        font-size: 1.1rem;
      }

      .resume-me {
        display: flex;
        width: 25%;
        font-size: 1.2rem;
        text-decoration: none;
        color: var(--link-color);
        position: relative;
        align-items: center;
        justify-content: flex-start;

        @include mobile {
          width: 100%;
          font-size: 1.2rem;
          padding-bottom: 20px;
        }

        .resume-me-icon {
          margin-top: 5px;
          margin-left: 5px;
          height: 100%;
          display: flex;
        }
      }

      .effect-shine:hover {
        -webkit-mask-image: linear-gradient(
          -75deg,
          rgba(0, 0, 0, 0.6) 30%,
          #000 50%,
          rgba(0, 0, 0, 0.6) 70%
        );
        -webkit-mask-size: 200%;
        animation: shine 2s infinite;
      }
    }
  }

  .contact-me {
    display: flex;
    width: 550px;
    height: 550px;
    background-color: var(--secondary-color);
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    align-items: center;
    position: relative;
    z-index: 51;

    @include mobile {
      width: 95%;
      height: 100%;
      border-radius: 0%;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
    }

    .form {
      width: 100%;
      height: 100%;
      padding: 20px 30px;
      max-width: calc(100vw - 40px);
      box-sizing: border-box;
      position: relative;

      @include mobile {
        width: 100%;
        height: 100%;
        max-width: 100%;
        padding: 10px 20px;
        overflow-y: scroll;
      }
    }

    .form-content {
      display: block;
      overflow: auto;
      height: 100%;
      color: rgba($color: #fff, $alpha: 0.85);

      // h2 {
      //   margin: 10px 0;
      //   padding-bottom: 10px;
      //   width: 180px;
      //   color: var(--about-contactMe-title-color);
      //   border-bottom: 3px solid var(--about-contactMe-title-color);
      //   @include mobile {
      //     font-size: 1.3rem;
      //   }
      // }
      .contact-me-title {
        @include mobile {
          font-size: 1.5rem;
          text-align: center;
        }
      }

      input,
      select,
      textarea {
        color: rgba($color: #fff, $alpha: 0.85);
      }

      input {
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
        background: none;
        outline: none;
        resize: none;
        border: 0;
        transition: all 0.3s;
        border-bottom: 2px solid var(--footer-border);

        @include mobile {
          font-size: 0.8rem;
          padding: 5px;
        }
      }

      textArea {
        min-width: 95%;
        max-width: 95%;
        min-height: 100px;
        max-height: 150px;
        padding: 10px;
        padding-bottom: 0;
        background: none;
        border: 0;
        outline: none;
        transition: all 0.3s;
        border-bottom: 2px solid var(--footer-border);

        @include mobile {
          width: 100%;
          min-height: 15px;
          max-height: 50px;
        }
      }

      textArea:focus,
      input:focus {
        border-bottom: 2px solid rgba($color: #fff, $alpha: 0.85);
      }

      p:before {
        content: attr(type);
        display: block;
        margin: 28px 0 0;
        font-size: 14px;

        @include mobile {
          // font-size: 0.9rem;
          margin: 20px 0 0;
        }
      }
    }

    div {
      &.bottom-contact {
        display: flex;
        position: absolute;
        bottom: -15px;
        right: -20px;
        background-color: var(--footer-border);
        color: rgba($color: #fff, $alpha: 0.85);
        width: 185px;
        padding: 10px;
        border-radius: 10px;
        font-size: 0.85rem;
        box-shadow: 10px 10px 40px -14px #212529;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        @include mobile {
          bottom: -15px;
          left: 0;
          right: 0;
          width: 100%;
          padding: 10px 8px 10px 0;
          align-items: center;
          justify-content: center;
          text-align: center;
          font-size: 0.8rem;
          border-radius: 0%;
          border-bottom-left-radius: 20px;
          border-bottom-right-radius: 20px;
        }
      }

      span {
        margin: 0 5px 0 15px;

        &.email {
          margin: 0;
        }
      }

      .contact-icon {
        margin-right: 10px;
      }
    }
  }
}

.error-message {
  // background-color: #000;
  color: red;
  font-size: 0.8rem;
  margin-top: 5px;
}

@keyframes spin {
  0% {
    transform: rotateZ(0);
  }

  100% {
    transform: rotateZ(720deg);
  }
}

$color: rgba(
  $color: #fff,
  $alpha: 0.85,
);

.submit-button {
  float: right;
  position: relative;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  margin: 15px 25px;
  padding: 0;
  width: 155px;
  height: 45px;
  border-radius: 25px;
  background-color: transparent;
  outline: 0;
  cursor: pointer;
  overflow: hidden;
  right: 0%;

  @include mobile {
    width: 120px;
    height: 40px;
    margin: 15px 0;
  }
}

.spin {
  border: 2px solid $color;
  color: rgba($color: #fff, $alpha: 0.85);
  font-weight: 600;
  transition: all 200ms ease-in-out;

  .submit-span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 100%;
    transition: opacity 200ms ease-in-out;
    font-weight: bold;

    @include mobile {
      width: 75%;
    }

    &:last-child {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
    }
  }

  svg {
    height: 30px;
    fill: var(--highText-color);
  }

  &:hover {
    background-color: $color;
    color: #000;
    border: $color;
  }

  &.done,
  &.processing {
    pointer-events: none;
    cursor: default;
  }

  &.processing {
    width: 45px;
    border-width: 4px;
    border-right-color: var(--footer-border);
    border-bottom-color: var(--footer-border);

    animation: spin;
    animation-delay: 200ms;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;

    @include mobile {
      width: 40px;
    }

    &:hover {
      background-color: transparent;
    }

    span:first-child {
      opacity: 0;
    }
  }

  &.done {
    &:hover {
      background-color: transparent;
    }

    span:first-child {
      opacity: 0;
    }

    span:last-child {
      opacity: 1;
    }
  }
}

.contact-close {
  // background-color: red;
  color: rgba($color: #fff, $alpha: 0.85);
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 35px;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    color: rgba($color: #fff, $alpha: 0.35);
    transition: all 0.3s;
  }
}
.overlay-contact {
  background-color: var(--overlay-background);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 48; // Lower z-index than the contact-bar
}
@keyframes shine {
  from {
    -webkit-mask-position: 150%;
  }

  to {
    -webkit-mask-position: -50%;
  }
}
