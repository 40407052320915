@import "../../global.scss";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&family=Poppins:wght@400;700&display=swap");
@import url("https://cdn.jsdelivr.net/gh/rastikerdar/vazir-font@v30.1.0/dist/font-face.css");

.expCard-content {
  pointer-events: auto;
  position: relative;
  border-radius: 12px;
  background-color: var(--card-background);
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.expanded .expCard-content {
  max-width: 900px;
  overflow-y: scroll;

  @include mobile {
    max-width: 100%;
    border-radius: 0%;
  }
}

.expCard {
  width: 100%;
  height: 100%;
  position: relative;
  display: block;
  z-index: 1;
}

.content-container {
  padding: 0px 35px;
  padding-bottom: 35px;
  max-width: 700px;
  width: 90vw;

  @include mobile {
    max-width: 100%;
    width: 100%;
  }

  & > p {
    color: var(--readableText-color);
    font-size: 1.2rem;
    line-height: 28px;
    margin-bottom: 30px;
  }
}

.content-tools {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  color: var(--mediumText-color);
  margin: 20px 0;

  .content-tools-item {
    background-color: rgba($color: #575757, $alpha: 0.15);
    padding: 10px 15px;
    border-radius: 50px;
  }
}

.imagePro {
  width: 500px;
  height: 500px;
  object-fit: contain;

  @include mobile {
    width: 350px;
    height: 350px;
  }

  &.cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.content-image {
  border-radius: 10px;
  border: var(--highText-color) solid 3px;
  height: 300px;
  width: 100%;

  @include mobile {
    height: 215px;
    width: 100%;
  }

  &.mobile {
    width: 30%;
    @include mobile {
      height: 265px;
      width: 45%;
    }
  }
}

.content-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-title {
  display: flex;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  text-align: center;
  color: var(--highText-color);
  font-size: 2rem;
  font-weight: bold;
  cursor: pointer;
}

.expanded {
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 5;
  overflow: hidden;
  padding: 40px 0;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0.8);

  @include mobile {
    padding: 0px 0;
    border-radius: 0%;
  }
}

.expCard-image-container {
  position: relative;
  overflow: hidden;
  height: 300px;
  width: 100%;
  border: 2px solid var(--footer-border);
  border-radius: 15px;
  transition: border 0.25s ease-in-out;

  .noContainer {
    border: 0px;
    border-radius: 0px;
  }

  .expCard-title-container {
    background-color: var(--card-background);
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;

    .expCard-title {
      font-size: 1.1em;
      font-weight: bold;
      color: var(--highText-color);
      margin-right: 10px;
    }

    .expand-icon {
      font-size: 1.3em;
      color: var(--highText-color);
    }

    .expCard-date {
      font-size: 0.9em;
      color: var(--mediumText-color);
    }

    .expand-icon {
      color: var(--card-background);
      transition: color 0.3s ease-in-out;
    }
  }
}

.expanded .expCard-image-container {
  height: 420px;
  border: 0px solid var(--lowText-color);
  border-radius: 0px;
}

.expCard-image {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.compact-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color: #000000, $alpha: 0.35);
  border-radius: 15px;
  z-index: 5;
  height: 300px;
  transition: opacity 0.25s ease-in-out;
}

.compact {
  width: 100%;
  height: 100%;
}

.expanded .title {
  font-size: 5em;
  color: var(--highText-color);
}

.compact .title {
  font-size: 1.5em;
}

.expCard-container {
  position: relative;
  height: 285px;
  width: 525px;

  &:hover {
    .compact {
      .expCard-image-container {
        border: 2px solid #fff;
        transition: border 0.3s ease-in-out;

        .noContainer {
          border: 0px;
        }

        .expCard-title-container {
          background-color: var(--card-background);
          transition: background-color 0.3s ease-in-out;
        }

        .expand-icon {
          color: var(--highText-color);
          transition: color 0.3s ease-in-out;
        }
      }
    }

    .compact-overlay {
      opacity: 0.5;
      transition: opacity 0.3s ease-in-out;
    }
  }

  @include mobile {
    width: 95%;
  }
}

.overlay {
  z-index: 1;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  will-change: opacity;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  display: none;

  &.open {
    display: block;
  }
}

.expCard-close {
  display: none;
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: rgba($color: #575757, $alpha: 0.5);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: rgba($color: #fff, $alpha: 0.5);
  font-size: 1.8rem;
  z-index: 5;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: rgba($color: #575757, $alpha: 0.8);
    color: rgba($color: #fff, $alpha: 1);
    transition: all 0.2s ease-in-out;
  }

  @include mobile {
    top: 40px;
    left: 20px;
  }
}

.expanded .expCard-close {
  display: flex;
}

.expCard-main {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  // height: 100%;
}

.content-container {
  flex: 2; // Take up 2/3 of the space
  padding: 35px;
  max-width: 700px;
  // display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  .content-title {
    color: var(--readableText-color);
    font-size: 4rem;
    line-height: 28px;
    margin-bottom: 30px;
  }

  p,
  ul {
    color: var(--readableText-color);
    font-size: 1.3rem;
    line-height: 30px;
  }

  ul {
    list-style-type: disc;
    padding-left: 20px;
    //add gap between list items
    li {
      margin-bottom: 10px;
    }
  }

  .task-bubble-container {
    margin-top: 50px;
  }
  .task-bubble {
    background-color: var(--card-background);
    color: var(--highText-color);
    border-radius: 12px;
    border: 1px solid var(--highText-color);
    padding: 10px 15px;
    margin-right: 10px;
    margin-bottom: 10px;
    display: inline-block;
  }
}

.right-side-image {
  flex: 1; // Take up 1/3 of the space
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;

  .expCard-side-image {
    width: 100%;
    height: auto;
    border-radius: 15px;
    object-fit: fill;
    max-height: 400px;

    &.analysis-image {
      width: 80%;
    }
  }
}

.expanded .expCard-content {
  max-width: 900px;
  overflow-y: scroll;

  .analysis-preparation-container {
    display: flex;
    flex-direction: column;
    margin-top: 25px;
    padding: 0 25px;

    h1 {
      font-size: 1.8rem;
      color: var(--mediumText-color);
      text-align: center;
      margin-top: -5px;
    }

    h2 {
      font-weight: 400;
      font-size: 1.3rem;
      color: var(--lowText-color);
      text-align: center;
    }

    .analysis-container {
      display: flex;
      margin-bottom: 100px;
      h3 {
        font-size: 1.4rem;
        color: var(--highText-color);
      }
    }

    .preparation-description {
      font-size: 1.2rem;
      color: var(--readableText-color);
      margin-top: 10px;
      width: 80%;
    }

    .color-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      margin-bottom: 120px;

      .color-bubble-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 70px;
        &:last-child {
          margin-right: 0;
        }

        &.alternate {
          margin-top: -10px;
        }

        color: var(--highText-color);

        .color-bubble {
          width: 100px;
          height: 100px;
          border-radius: 50%;
        }
      }
    }

    .font-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: 7rem;
      margin-bottom: 100px;

      h3 {
        font-size: 1.6rem;
        color: var(--highText-color);
        margin-bottom: 20px;
        text-align: left;
      }
    }

    .font-bubble-container {
      // border: 1px solid #ddd;
      padding: 1rem;
      // border-radius: 8px;
      text-align: center;
      // max-width: 200px;
    }

    .font-samples p {
      margin: 0.5rem 0;
      font-size: 1.4rem;
      color: var(--readableText-color);
      text-align: left;
    }
  }

  .design-description-container {
    //grid template columns
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5px;
    margin-top: 10px;
    padding: 0 25px;
    color: var(--readableText-color);
  }
}

.video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 50px;
}
