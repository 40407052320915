@import "../../global.scss";

/*Light-Mode*/
:root {
  --loader-color: #fff;
  --background-color: #121212;
  --blur-background: rgba(29, 29, 31, 0.7);
  --overlay-background: rgba(0, 0, 0, 0.65);
  --highText-color: rgba(255, 255, 255, 0.85);
  --mediumText-color: rgba(255, 255, 255, 0.6);
  --lowText-color: rgba(255, 255, 255, 0.35);
  --readableText-color: #9d9ca1;
  --primary-color: #695681;
  --secondary-color: #36184f;
  --tertiary-color: #32243e;
  --card-background: #1c1c1e;
  --project-background: #1d1d1f;
  --footer-border: #575757;
  --link-color: #3997f7;
}

/* Dark-Mode */
[data-theme="light"] {
  --loader-color: #121212;
  --background-color: #fafafa;
  --blur-background: rgba(255, 255, 255, 0.7);
  --navbar-border: #e6e6e6;
  --overlay-background: rgba(0, 0, 0, 0.65);
  --highText-color: rgba(0, 0, 0, 0.85);
  --mediumText-color: rgba(0, 0, 0, 0.6);
  --lowText-color: rgba(0, 0, 0, 0.35);
  --readableText-color: #9d9ca1;
  --primary-color: #695681;
  --secondary-color: #36184f;
  --tertiary-color: #32243e;
  --card-background: #fff;
  --project-background: #fff;
  --footer-border: #575757;
  --link-color: #3997f7;
}

.theme-container {
  position: absolute;
  @include mobile {
    right: 110px;
  }
  .theme-icon {
    color: var(--highText-color);
    font-size: 1.5em;
    transition: all 0.25s;
    @include mobile {
      font-size: 1.7em;
    }
  }
}
