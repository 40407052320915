@import "../../global.scss";

nav {
  .navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .hamburger {
      display: none;

      @include mobile {
        display: flex;
        height: 100%;
        align-items: center;
        cursor: pointer;
      }
    }

    .website_name {
      display: flex;
      color: var(--highText-color);
      font-weight: 600;
      letter-spacing: 1px;
      padding: 4px 8px;
      border-radius: 2px;
      opacity: 0.5;
      transition: all 0.4s ease;
      font-size: 2.2rem;
      cursor: none;

      @include mobile {
        font-size: 2.5rem;
      }

      &:hover {
        opacity: 1;

        .dot {
          animation: bounce 1.65s cubic-bezier(0.24, 0, 0.5, 0.84) 0.45s infinite;
          -webkit-animation: bounce 1.65s cubic-bezier(0.24, 0, 0.5, 0.84) 0.45s infinite;
          -moz-animation: bounce 1.65s cubic-bezier(0.24, 0, 0.5, 0.84) 0.45s infinite;
        }
      }

      .bounce {
        display: inline-block;
      }
    }

    .navbar_list {
      display: flex;
      transition: all 0.4s ease;
      opacity: 0.5;
      list-style: none;
      flex-direction: row;

      @include mobile {
        display: none;
      }

      &:hover {
        opacity: 1;
      }

      .navbar_link {
        margin-right: 40px;
        cursor: pointer;

        &:last-child {
          margin-right: 0;
        }
      }

      .link {
        color: var(--highText-color);
        text-transform: uppercase;
        font-weight: 400;
        letter-spacing: 1.1px;
        position: relative;
        transition: all 0.3s 0.2s ease;
        text-decoration: none;
        font-size: 0.85rem;

        &:before {
          content: "";
          position: absolute;
          width: 0px;
          height: 4px;
          background: var(--primary-color);
          bottom: -10px;
          border-radius: 5px;
          transition: all 0.4s cubic-bezier(0.82, 0.02, 0.13, 1.26);
          left: 100%;
        }

        &:hover {
          opacity: 1;
          color: var(--highText-color);

          &:before {
            width: 40px;
            left: 0;
          }
        }
      }
    }
  }
}

@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0);
  }

  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }

  30% {
    transform: scale(0.9, 1.1) translateY(-20px);
  }

  35% {
    transform: scale(0.9, 1.1) translateY(-20px);
  }

  50% {
    transform: scale(1.05, 0.95) translateY(0);
  }

  57% {
    transform: scale(1.05, 0.95) translateY(-5px);
  }

  64% {
    transform: scale(1, 1) translateY(0);
  }

  100% {
    transform: scale(1, 1) translateY(0);
  }
}

.nav-mobile {
  display: none;

  @include mobile {
    display: flex;
  }
}

aside {
  background-color: var(--secondary-color);
  height: 100vh;
  position: fixed;
  z-index: 5;
  width: 100%;
}

.nav-background {
  position: fixed;
  background-color: var(--overlay-background);
  height: 100vh;
  z-index: 2;
}

.nav-side {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  flex-direction: column;

  a {
    color: rgba($color: #fff, $alpha: 0.85);
    text-decoration: none;
    font-size: 1.25rem;
    font-weight: 600;
    display: block;
    margin: 20px;
  }
}

.side-socials {
  display: flex;
  position: absolute;
  bottom: 10%;
  flex-direction: row;

  a {
    display: block;
    color: rgba($color: #fff, $alpha: 0.85);
    text-decoration: none;
    font-size: 1.8rem;
    margin: 20px;
  }
}

.ham {
  display: none;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: transform 400ms;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 70px;

  @include mobile {
    display: block;
  }
}

.hamRotate.active {
  transform: rotate(45deg);
}

.hamRotate180.active {
  transform: rotate(180deg);
}

.line {
  fill: none;
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
  stroke: var(--highText-color);
  stroke-width: 4;
  stroke-linecap: round;
}

.ham6 .top {
  stroke-dasharray: 40 172;
}

.ham6 .middle {
  stroke-dasharray: 40 111;
}

.ham6 .bottom {
  stroke-dasharray: 40 172;
}

.ham6.active .top {
  stroke-dashoffset: -132px;
}

.ham6.active .middle {
  stroke-dashoffset: -71px;
}

.ham6.active .bottom {
  stroke-dashoffset: -132px;
}