@import "../../global.scss";
$bg-01: #695681;
$bg-02: #36184f;
$bg-03: #32243e;

$circleShadow: inset 5px 20px 40px rgba($bg-02, 0.25),
  inset 5px 0px 5px rgba($bg-03, 0.3), inset 5px 5px 20px rgba($bg-03, 0.25),
  2px 2px 5px rgba(white, 0.2);

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;

  .circle__container {
    display: flex;
    margin: 0 auto;
    transition: all 0.4s ease;
    justify-content: center;
    align-items: center;
    position: relative;

    // Scene for the Parallax Effect
    .scene {
      position: absolute;
      width: 100vw;
      height: 100vh;
      vertical-align: middle;
    }

    // All elements' containers
    .one,
    .circle,
    .name {
      width: 60%;
      height: 60%;
      top: 20% !important;
      left: 20% !important;
      min-width: 400px;
      min-height: 400px;
      @include mobile {
        width: 100%;
        left: 0% !important;
      }

      .content {
        width: 600px;
        height: 600px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        animation: content 0.8s 3s cubic-bezier(1, 0.06, 0.25, 1) backwards;

        @keyframes content {
          0% {
            width: 0;
          }
        }
      }
    }
    .name {
      font-size: 6.4rem;
      font-weight: 600;
      letter-spacing: 4px;
      display: flex !important;
      justify-content: center;
      align-items: center;
      position: absolute;
      z-index: 1;
      animation: showName 0.6s cubic-bezier(0.3, 0.8, 1, 1.05) both;
      animation-delay: 1.6s;
      span {
        color: var(--highText-color);
        font-size: 6.4rem;
        font-weight: bold;
        display: inline-block;
        letter-spacing: 5px;
        transition: 1.85s;
        text-shadow: 0px 0px 0px var(--primary-color);
        position: relative;
        top: 0px;
        left: 0px;
        cursor: default;
        @include mobile {
          font-size: 5.5rem;
          letter-spacing: 3px;
        }
        &:hover {
          text-shadow: -8px 8px 0px var(--primary-color);
          transition: 0.3s;
          top: -8px;
          left: 8px;
        }
        &.space {
          width: 20px;
          height: 100%;
        }
      }

      @keyframes showName {
        0% {
          opacity: 0;
          transform: scale(10) skew(20deg, 20deg);
        }
      }

      &:nth-of-type(2) {
        color: var(--secondary-color);
        z-index: 1;
        animation-delay: 1s;
        filter: blur(10px);
        opacity: 0.8;
      }
    }

    // Bigger Circle
    .circle {
      position: absolute;

      &:before {
        content: "";
        position: absolute;
        width: 650px;
        height: 650px;
        background-color: rgba($bg-02, 0.2);
        border-radius: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        box-shadow: $circleShadow;
        animation: circle 0.8s 0.5s cubic-bezier(1, 0.06, 0.25, 1) backwards;
        @include mobile {
          width: 600px;
          height: 600px;
        }
        @keyframes circle {
          0% {
            width: 0;
            height: 0;
          }
        }
      }
    }

    // Container 1
    .one {
      .content {
        // Smaller Circle
        &:before {
          content: "";
          position: absolute;
          width: 450px;
          height: 450px;
          background-color: rgba($bg-02, 0.3);
          border-radius: 100%;
          box-shadow: $circleShadow;
          animation: circle 0.8s 0.9s cubic-bezier(1, 0.06, 0.25, 1) backwards,
            pulse 3s 2.5s 20;
          box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
          transform: scale(1);
          // animation: pulse-black 3s;
          // animation-iteration-count: 50;
          @include mobile {
            width: 400px;
            height: 400px;
          }
        }
      }
    }
  }
}

.title {
  width: 100%;
  top: 7% !important;
  min-width: 400px;
  min-height: 400px;
  color: var(--mediumText-color);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  text-align: center;
  @include mobile {
    width: 100%;
    left: 0% !important;
    text-align: center;
  }
}

.subtitle {
  width: 100%;
  top: 61% !important;
  color: var(--mediumText-color);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  overflow: hidden;
  white-space: nowrap;
  font-style: italic;
}

.intro-more {
  position: relative;
  bottom: -250px;
  width: 100%;
  color: #fff;
  display: flex;
  font-size: 1.35rem;
}

.scrollDown {
  width: 40px;
  height: 40px;
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  margin: 0 auto;
  // z-index: -1;
  opacity: 1;
  transition: all 0.5s ease-in-out;
  &.deactive {
    transition: all 0.5s ease-in-out;
    opacity: 0;
  }
  &::before {
    display: block;
    position: absolute;
    left: 50%;
    top: 0;
    content: "";
    border-radius: 50%;
    width: 20px;
    height: 20px;
    transform: translate(-50%, 40px) scale(0.5, 0.5);
    background-color: var(--highText-color);
    animation: scrollDown 1.5s linear infinite;
    animation-delay: 1s;
    z-index: -1;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 var(--highText-color);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@keyframes scrollDown {
  0% {
    transform: translate(-50%, 40px) scale(0.5, 0.5);
    opacity: 1;
    animation-timing-function: ease-in;
  }
  10% {
    transform: translate(-50%, 20px) scale(0.4, 0.65);
    animation-timing-function: ease-out;
  }
  20% {
    transform: translate(-50%, 0px) scale(0.6, 0.4);
    animation-timing-function: ease;
  }
  30% {
    transform: translate(-50%, 0px) scale(0.5, 0.5);
    animation-timing-function: ease-in;
    opacity: 1;
    filter: blur(0px);
  }
  50% {
    transform: translate(-50%, 0px) scale(0.9, 0.9);
    opacity: 0.4;
    filter: blur(0.5px);
  }
  65% {
    transform: translate(-50%, 20px) scale(1, 1.2);
    filter: blur(1px);
    opacity: 0.3;
  }
  80% {
    transform: translate(-50%, 40px) scale(1.05, 0.95);
    animation-timing-function: ease-out;
    opacity: 0.4;
    filter: blur(0.5px);
  }
  100% {
    transform: translate(-50%, 40px) scale(0.5, 0.5);
    opacity: 1;
    filter: blur(0px);
  }
}
