@import "../../global.scss";

:root {
  --card-height: 40vw;
  --card-margin: 4vw;
  @include mobile {
    --card-height: 100vw;
    --card-margin: 15vh;
  }
  --card-top-offset: 1em;
  --numcards: 4;
}

#cards {
  padding-left: 0%;
  padding-bottom: calc(
    var(--numcards) * var(--card-top-offset)
  ); /* Make place at bottom, as items will slide to that position*/
  margin-bottom: var(
    --card-margin
  ); /* Don't include the --card-margin in padding, as that will affect the scroll-timeline*/
}

#card_1 {
  --index: 1;
}

#card_2 {
  --index: 2;
}

#card_3 {
  --index: 3;
}

#card_4 {
  --index: 4;
}

.card {
  position: sticky;
  top: 20vh;
  padding-top: calc(var(--index) * var(--card-top-offset));
}

@supports (animation-timeline: works) {
  @scroll-timeline cards-element-scrolls-in-body {
    source: selector(body);
    scroll-offsets:
  			/* Start when the start edge touches the top of the scrollport */ selector(
          #cards
        )
        start 1,
      /* End when the start edge touches the start of the scrollport */
        selector(#cards) start 0;
    start: selector(#cards) start 1; /* Start when the start edge touches the top of the scrollport */
    end: selector(#cards) start 0; /* End when the start edge touches the start of the scrollport */
    time-range: 4s;
  }

  .card {
    --index0: calc(var(--index) - 1); /* 0-based index */
    --reverse-index: calc(var(--numcards) - var(--index0)); /* reverse index */
    --reverse-index0: calc(
      var(--reverse-index) - 1
    ); /* 0-based reverse index */
  }

  .card__content {
    transform-origin: 50% 0%;
    will-change: transform;

    --duration: calc(var(--reverse-index0) * 1s);
    --delay: calc(var(--index0) * 1s);

    animation: var(--duration) linear scale var(--delay) forwards;
    animation-timeline: cards-element-scrolls-in-body;
  }

  @keyframes scale {
    to {
      transform: scale(calc(1.1 - calc(0.1 * var(--reverse-index))));
    }
  }
}
/** PAGE STYLING **/

.projects {
  max-width: 750px;
  @include mobile {
    width: 95%;
  }
}

#cards {
  list-style: none;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(var(--numcards), var(--card-height));
  gap: var(--card-margin);
}

.card__content {
  // box-shadow: 0px 0.1em 0.5em rgba(225, 225, 225, 0.4);
  // 0 0.1em 1em rgba(225, 225, 225, 0.1);
  box-shadow: 0px -2px 4px 0px var(--lowText-color);
  border: var(--footer-border) solid 0.5px;
  background-color: var(--project-background);
  color: var(--highText-color);
  border-radius: 1em;
  overflow: hidden;
  display: flex;
  padding: 20px;
  @include mobile {
    padding: 15px;
  }
}

.card__content > div {
  width: 50%;
  display: flex;
  justify-content: center;
}

.card__content > figure {
  grid-area: img;
}

.card__image__background {
  height: 300px;
  @include mobile {
    height: 250px;
  }
}

.projects-title {
  color: var(--highText-color);
  text-align: center;
}

.card__image {
  position: absolute;
  width: 350px;
  height: 400px;
  top: 0;
  left: 385px;
  @include mobile {
    width: 200px;
    height: 250px;
    left: 170px;
    top: 45px;
  }
}

.card__image.num2 {
  width: 350px;
  height: 350px;
  top: 50px;
  @include mobile {
    width: 185px;
    height: 185px;
    left: 185px;
    top: 85px;
  }
}

.card__image.num3 {
  width: 200px;
  height: 325px;
  top: 70px;
  left: 450px;
  @include mobile {
    width: 170px;
    height: 270px;
    left: 200px;
    top: 70px;
  }
}

.card__image.num4 {
  width: 300px;
  height: 300px;
  left: 385px;
  top: 85px;
  @include mobile {
    width: 220px;
    height: 210px;
    left: 150px;
    top: 120px;
  }
}

.card__description {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card__description-text {
  display: flex;
}

.card__description-text__span {
  margin-left: 10px;
  margin-top: 3px;
}

.card__title {
  font-weight: 300;
  font-size: 2.5em;
  @include mobile {
    font-size: 1.5em;
  }
}

.card-links {
  display: flex;
  margin-top: 50px;
  justify-content: space-between;
  align-items: center;
  font-size: 25px;
  @include mobile {
    font-size: 23px;
  }
  .git-link {
    color: var(--highText-color);
    &:hover {
      color: #967bb6;
    }
  }
  .react-icon {
    margin-right: 10px;
    color: var(--highText-color);
    &:hover {
      // react color
      color: #61dafb;
    }
  }
  .figma-icon {
    margin-right: 10px;
    color: var(--highText-color);
    &:hover {
      // figma color
      color: #ff3a00;
    }
  }
  .redux-icon {
    color: var(--highText-color);
    &:hover {
      // redux color
      color: #764abc;
    }
  }
}

.tools-container {
  background-color: var(--project-background);
  // box-shadow: 0 0.1em 0.45em rgba(225, 225, 225, 0.4),
  //   0 1em 1.5em rgba(255, 255, 255, 0.1);
  box-shadow: 0px 0px 5px 2px var(--lowText-color);
  border-radius: 10px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
}
