@import "./global.scss";

#loader {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  background-color: var(--background-color);
  pointer-events: none;
  color: var(--highText-color);

  .white {
    position: absolute;
    top: -500px;
    width: 100%;
    height: 250px;
    background-color: var(--loader-color);
    transform: translateZ(0); /* Ensure GPU acceleration */
    will-change: transform; /* Browser optimizations */
  }

  .logo-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 3.5rem;
    will-change: transform;
    overflow: hidden;

    .logo-text {
      .dot {
        display: inline-block;

        @media (prefers-reduced-motion: no-preference) {
          animation: bounce 1.5s ease-in-out;
          will-change: translate;
        }
      }
    }
  }
}

.App {
  min-height: 100vh;

  .sections {
    width: 100%;
    height: calc(100vh - 70px);
    position: relative;
    top: 70px;
    scroll-behavior: smooth;
  }
}

.container {
  width: 90%;
  max-width: 1000px;
  margin: 0 auto;
}

.container.container__large {
  width: 100%;
  max-width: 1200px;
}

.nav {
  display: flex;
  position: fixed;
  width: 100%;
  top: 0;
  height: 60px;
  background-color: var(--blur-background);
  border-bottom: 1px solid var(--navbar-border);
  bottom: auto;
  z-index: 3;
  padding-top: 10px;
  padding-bottom: 10px;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;

  @include mobile {
    padding: 0px;
  }

  &.dismissed {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
}

.progress {
  position: fixed;
  bottom: 30px;
  right: 30px;
  opacity: 1;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  box-shadow: 0 0.2em 1em rgba(0, 0, 0, 0.1), 0 1em 2em rgba(0, 0, 0, 0.1);
  display: grid;
  place-items: center;
  cursor: pointer;
  z-index: 4;
  transition: bottom 0.3s ease-in-out;

  @include mobile {
    display: none;
  }

  &.hidden {
    bottom: -50px;
    transition: bottom 0.3s ease-in-out;
    // opacity: 0;
  }
}

#progress-value {
  display: block;
  height: calc(100% - 5px);
  width: calc(100% - 5px);
  background-color: var(--background-color);
  border-radius: 50%;
  display: grid;
  place-items: center;
  font-weight: 500;

  .scroll-icon {
    color: var(--highText-color);
  }
}

@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0);
  }

  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }

  30% {
    transform: scale(0.9, 1.1) translateY(-30px);
  }

  35% {
    transform: scale(0.9, 1.1) translateY(-30px);
  }

  50% {
    transform: scale(1.05, 0.95) translateY(0);
  }

  57% {
    transform: scale(1.05, 0.95) translateY(-5px);
  }

  64% {
    transform: scale(1, 1) translateY(0);
  }

  100% {
    transform: scale(1, 1) translateY(0);
  }
}
