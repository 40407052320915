@import "../../global.scss";

.footer {
  padding: 10px 0;
  border-top: 0.5px solid var(--footer-border);

  @include mobile {
    padding: 20px 0;
  }

  footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--highText-color);

    @include mobile {
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
    }

    .copyRight {
      font-size: 0.9rem;
    }

    .socialMedia {
      display: flex;
      flex-direction: row;
      align-items: center;

      @include mobile {
        flex-direction: column;
      }

      .social-links-footer {
        display: flex;
        font-size: 1.8rem;
        margin-left: 25px;
        padding-top: 5px;
        align-items: center;
        justify-content: center;

        @include mobile {
          margin-top: 10px;
          margin-left: 0%;
        }

        .social-link-footer {
          &:nth-child(1) {
            margin-left: 0;
          }

          margin-left: 20px;

          opacity: 0.6;
          transition: all 0.2s ease-in-out;

          &:hover {
            opacity: 1;
            transition: all 0.21s ease-in-out;
          }
        }
      }
    }
  }
}

// And from the left
.slide:hover,
.slide:focus {
  box-shadow: inset 7.5em 0 0 0 var(--hover);
}

$colors: (
  slide: #695681,
);

// Sass variables compile to a static string; CSS variables are dynamic and inherited
// Loop through the map and set CSS custom properties using Sass variables
@each $button, $color in $colors {
  .#{$button} {
    --color: #{$color};
    --hover: #{adjust-hue($color, 45deg)};
  }
}

button {
  color: var(--color);
  transition: 0.25s;

  &:hover,
  &:focus {
    border-color: var(--hover);
    color: var(--highText-color);
  }
}

button {
  background: none;
  border: 2px solid;
  border-radius: 50px;
  font: inherit;
  line-height: 1;
  padding: 10px 20px;
  font-size: 0.9rem;
  cursor: pointer;

  @include mobile {
    margin-bottom: 10px;
  }
}

.social-icon-footer {
  color: var(--highText-color);
}