.expanding-button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: none;
  color: var(--button-color, white);
  font-size: 16px;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.3s ease;

  &:hover {
    .text {
      opacity: 0.8;
      scale: 1;
    }

    .icon {
      transform: rotate(-30deg);
      opacity: 0.8;
    }
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    transition: transform 0.3s ease;
    transform: rotate(-45deg);
  }

  .text {
    opacity: 0;
    margin-right: 6px;
    white-space: nowrap;
    overflow: hidden;
    font-size: 14px;
    color: var(--button-color);
    scale: 0;
    transition: all 0.3s ease, margin-left 0.3s ease;
  }
}
